<template>
  <v-app id="inspire">
    <v-main>
      <Navigation :authenticatedUser="auth_user"> <!---:roles="role_names" -->
        <template v-slot:items>
          <SiderBarItems :items="side_nav_items" :roles="role_names" :permissions="permission_names" usertype="faculty" environment="main"/>
        </template>
      </Navigation>
      <template>
        <div class="container mx-6 my-4 mx-auto">
          <Overview v-if="$route.fullPath === '/faculty'" v-bind:authenticatedUser="auth_user"></Overview>
        </div>
        <router-view :key="$route.fullPath" />
      </template>
    </v-main>
  </v-app>
</template>
  
<script>
import User from "../../../apis/User";
import Navigation from '../../Navigation_modules/CustomNavigationDrawer';
import SiderBarItems from '../../Navigation_modules/SideBarItems';
import Overview from './FacultyOverview.vue';

export default {
  name: "FacultyDashboard",
  components: { Navigation, Overview, SiderBarItems },

  data() {
    return {
      auth_user: null,
      permission_names: [],
      role_names: [],
      side_nav_items: [],

      side_nav_items: [
        { text: 'Dashboard', icon: 'dashboard', route: 'overview', permissions: [] },
        { text: 'Departments', icon: 'department', route: 'departments', permissions:[] },
        { text: 'Staff', icon: 'lecturer', route: 'staff', permissions:[] },
        { text: 'Students', icon: 'students', route: 'students', permissions:[] },
        { text: 'Student Registration List', icon: 'courses', route: 'students-registration-list', permissions:[] },
        { text: 'Students Results', icon: 'students', route: 'students-results', permissions:[] },
        { text: 'Programmes', icon: 'programmes', route: 'programmes', permissions:[] },
        { text: 'Courses', icon: 'courses', route: 'courses', permissions:[] },
        //   { text: 'Course Allocations', icon: 'course-allocation', route: 'course-allocations', permissions: [] },
          { text: 'Course Registrations', icon: 'students', route: 'course-registrations',permissions: []},
          { text: 'Extra Credit Applications', icon: 'course-reg', route: 'extra-credit-applications', permissions:[]},
          { text: 'Head of Departments', icon: 'academic-advisers', route: 'hods', permissions:[] },
        { text: 'Academic Advisers', icon: 'academic-advisers', route: 'academic-advisers', permissions:[] },
          { text: 'Examination Officers', icon: 'examination-office', route: 'exam-officers', permissions:[] },
          { text: 'Graduation List', icon: 'examination-office', route: 'graduation-management/list', permissions: [] },
        
      ]
    }
  },
  mounted() {
    User.auth().then(response => {
      this.auth_user = response.data.data;
    });
  }
}
</script>
  
<style></style>
  